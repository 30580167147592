@font-face {
	font: {
		family: 'Synthemesc';
		weight: normal;
		style: normal;
		display: block;
	}

	src: url('../public/fonts/Synthemesc-Regular.woff2') format('woff2');
}
