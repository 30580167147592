@font-face {
	font: {
		family: 'Brandon Grotesque';
		weight: 100;
		style: normal;
		display: swap;
	}
	src: url('../public/fonts/BrandonGrotesque-Thin.woff2') format('woff2');
}

@font-face {
	font: {
		family: 'Brandon Grotesque';
		weight: 100;
		style: italic;
		display: swap;
	}
	src: url('../public/fonts/BrandonGrotesque-ThinItalic.woff2') format('woff2');
}

@font-face {
	font: {
		family: 'Brandon Grotesque';
		weight: 300;
		style: normal;
		display: swap;
	}
	src: url('../public/fonts/BrandonGrotesque-Light.woff2') format('woff2');
}

@font-face {
	font: {
		family: 'Brandon Grotesque';
		weight: 300;
		style: italic;
		display: swap;
	}
	src: url('../public/fonts/BrandonGrotesque-LightItalic.woff2') format('woff2');
}

@font-face {
	font: {
		family: 'Brandon Grotesque';
		weight: 400;
		style: normal;
		display: swap;
	}
	src: url('../public/fonts/BrandonGrotesque-Regular.woff2') format('woff2');
}

@font-face {
	font: {
		family: 'Brandon Grotesque';
		weight: 400;
		style: italic;
		display: swap;
	}
	src: url('../public/fonts/BrandonGrotesque-RegularItalic.woff2') format('woff2');
}

@font-face {
	font: {
		family: 'Brandon Grotesque';
		weight: 500;
		style: normal;
		display: swap;
	}
	src: url('../public/fonts/BrandonGrotesque-Medium.woff2') format('woff2');
}

@font-face {
	font: {
		family: 'Brandon Grotesque';
		weight: 500;
		style: italic;
		display: swap;
	}
	src: url('../public/fonts/BrandonGrotesque-MediumItalic.woff2') format('woff2');
}

@font-face {
	font: {
		family: 'Brandon Grotesque';
		weight: 700;
		style: normal;
		display: swap;
	}
	src: url('../public/fonts/BrandonGrotesque-Bold.woff2') format('woff2');
}

@font-face {
	font: {
		family: 'Brandon Grotesque';
		weight: 700;
		style: italic;
		display: swap;
	}
	src: url('../public/fonts/BrandonGrotesque-BoldItalic.woff2') format('woff2');
}

@font-face {
	font: {
		family: 'Brandon Grotesque';
		weight: 900;
		style: normal;
		display: swap;
	}
	src: url('../public/fonts/BrandonGrotesque-Black.woff2') format('woff2');
}

@font-face {
	font: {
		family: 'Brandon Grotesque';
		weight: 900;
		style: italic;
		display: swap;
	}
	src: url('../public/fonts/BrandonGrotesque-BlackItalic.woff2') format('woff2');
}
