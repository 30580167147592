// Fade (standard)

.fade-enter,
.fade-appear {
	opacity: 0;
}

.fade-enter-active,
.fade-appear-active {
	opacity: 1;
	transition: opacity var(--ease-time) var(--ease-fn);
}

.fade-exit {
	opacity: 1;
}

.fade-exit-active {
	opacity: 0;
	transition: opacity var(--ease-time) var(--ease-fn-inverse);
}

// Fade (slow)

.fade-enter-active.slow,
.fade-appear-active.slow {
	transition: opacity calc(var(--ease-time) * 2) var(--ease-fn);
}

.fade-exit-active.slow {
	transition: opacity calc(var(--ease-time) * 2) var(--ease-fn-inverse);
}

// Fade + Slide Down

@property --gradient-angle {
	syntax: "<angle>";
	initial-value: -135deg;
	inherits: false;
}

.modal-card-enter-active,
.modal-card-appear-active,
.modal-card-exit-active {
	transition: opacity calc(var(--ease-time) * 1.5) var(--ease-fn-inverse), transform calc(var(--ease-time) * 1.5) linear;
}

.modal-card-enter,
.modal-card-appear,
.modal-card-enter-active,
.modal-card-appear-active,
.modal-card-enter-done,
.modal-card-appear-done,
.modal-card-exit,
.modal-card-exit-active {
	&::before {
		transition: all calc(var(--ease-time) * 2) ease, --gradient-angle calc(var(--ease-time) * 16) var(--ease-sine-inverse);
	}
}

.modal-card-enter,
.modal-card-appear {
	opacity: 0;
	transform: scale(1.1) translateY(-100px);

	&::before {
		--gradient-angle: -135deg;
		visibility: hidden;
		transform: scale(0.975);
	}
}

.modal-card-enter-active,
.modal-card-appear-active {
	opacity: 1;
	transform: scale(1) translateY(0px);
}

.modal-card-enter-done,
.modal-card-appear-done {
	&::before {
		--gradient-angle: 45deg;
		visibility: visible;
		transform: scale(1);
	}
}

.modal-card-exit {
	opacity: 1;
	transform: scale(1) translateY(0px);
}

.modal-card-exit-active {
	opacity: 0;
	transform: scale(1.1) translateY(-100px);
}

// Slide from right to left

.slide-left-enter,
.slide-left-appear {
	transform: translateX(100%);
}

.slide-left-enter-active,
.slide-left-appear-active {
	transition: transform calc(var(--ease-time) * 3) var(--ease-fn-slide);
	transform: translateX(0);
}

.slide-left-exit {
	transform: translateX(0);
}

.slide-left-exit-active {
	transition: transform calc(var(--ease-time) * 3) var(--ease-fn-slide);
	transform: translateX(100%);
}

// Slide down (notification)

.notification-enter,
.notification-appear,
.notification-enter-active,
.notification-appear-active,
.notification-enter-done,
.notification-appear-done,
.notification-exit,
.notification-exit-active {
	transition: transform calc(var(--ease-time) * 2) ease, opacity calc(var(--ease-time) * 2) var(--ease-fn), border calc(var(--ease-time) * 2) var(--ease-fn), border-radius calc(var(--ease-time) * 2) var(--ease-fn);
}

.notification-enter,
.notification-appear,
.notification-exit-active {
	transform: translateY(-100%);
	opacity: 0;
}

.notification-enter-active,
.notification-appear-active,
.notification-exit {
	transform: translateY(0);
	opacity: 1;
}

// Scale + opacity

.scale-fade-enter,
.scale-fade-appear,
.scale-fade-enter-active,
.scale-fade-appear-active,
.scale-fade-exit,
.scale-fade-exit-active {
	transition: transform calc(var(--ease-time) * 2) ease, opacity calc(var(--ease-time) * 2) ease
}

.scale-fade-enter,
.scale-fade-appear,
.scale-fade-exit-active {
	transform: scale(0);
	opacity: 0;
}

.scale-fade-enter-active,
.scale-fade-appear-active,
.scale-fade-exit {
	transform: scale(1);
	opacity: 1;
}
