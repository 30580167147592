* {
	box-sizing: border-box;
	margin: 0;

	&::before,
	&::after {
		box-sizing: border-box;
	}
}

ul[role='list'],
ol[role='list'] {
	list-style: none;
}

html,
body {
	height: 100%;
}

html {
	scroll-behavior: smooth;
}

body {
	min-height: 100vh;
	font-size: 14px;
	line-height: 1.5;
	text-rendering: optimizeSpeed;

	-webkit-font-smoothing: antialiased;
}

a,
a:link,
a:hover,
a:focus,
a:active {
	text-decoration: none;
}

a:not([class]) {
	text-decoration-skip-ink: auto;
}

img,
picture,
video,
canvas,
svg {
	display: block;
	max-width: 100%;
}

input,
button,
textarea,
select {
	font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
	overflow-wrap: break-word;
}

button {
	padding: 0;
	cursor: pointer;
	background: none;
	border: none;
}

hr {
	border: none;
}

#root,
.root {
	isolation: isolate;
}
