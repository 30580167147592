@font-face {
	font: {
		family: 'Europa';
		weight: 300;
		style: normal;
		display: swap;
	}
	src: url('../public/fonts/Europa-Light.woff2') format('woff2');
}

@font-face {
	font: {
		family: 'Europa';
		weight: 300;
		style: italic;
		display: swap;
	}
	src: url('../public/fonts/Europa-LightItalic.woff2') format('woff2');
}

@font-face {
	font: {
		family: 'Europa';
		weight: 400;
		style: normal;
		display: swap;
	}
	src: url('../public/fonts/Europa-Regular.woff2') format('woff2');
}

@font-face {
	font: {
		family: 'Europa';
		weight: 400;
		style: italic;
		display: swap;
	}
	src: url('../public/fonts/Europa-RegularItalic.woff2') format('woff2');
}

@font-face {
	font: {
		family: 'Europa';
		weight: 700;
		style: normal;
		display: swap;
	}
	src: url('../public/fonts/Europa-Bold.woff2') format('woff2');
}

@font-face {
	font: {
		family: 'Europa';
		weight: 700;
		style: italic;
		display: swap;
	}
	src: url('../public/fonts/Europa-BoldItalic.woff2') format('woff2');
}
