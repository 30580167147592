@mixin ease-fn-pair($name, $cbx1, $cby1, $cbx2, $cby2) {
	--#{$name}: cubic-bezier(#{$cbx1}, #{$cby1}, #{$cbx2}, #{$cby2});
	--#{$name}-inverse: cubic-bezier(#{1 - $cbx2}, #{1 - $cby2}, #{1 - $cbx1}, #{1 - $cby1});
}

:root {
	--background-color: #000000;
	--primary-color: #ffffff;
	--muted-color-e: #eeeeee;
	--muted-color-0: #dddddd;
	--muted-color-1: #bbbbbb;
	--muted-color-2: #999999;
	--muted-color-3: #777777;
	--muted-color-4: #555555;
	--muted-color-5: #333333;
	--muted-color-6: #111111;
	--muted-color-alt-0: #1b2728;
	--accent-color-r: #ff4654;
	--accent-color-r-filter: invert(33%) sepia(70%) saturate(2431%) hue-rotate(334deg) brightness(115%) contrast(102%);
	--accent-color-o: #ff7f11;
	--accent-color-o-alt-0: #ff4500;
	--accent-color-y: #ffdf00;
	--accent-color-g: #53ff45;
	--accent-color-b: #0055f7;
	--accent-color-b-alt-0: #c0ddff;
	--accent-color-b-alt-1: #72d3f9;
	--accent-color-b-alt-2: #4aa3fd;
	--accent-color-b-alt-3: #127dcf;
	--accent-color-b-alt-4: #1e4784;
	--accent-color-b-alt-5: #243648;
	--accent-color-v: #7f5feb;
	--accent-color-v-alt-0: #ba9bc9;
	--overlay-opacity: 0.4;
	--backdrop-blur-0: 4px;
	--border-radius-0: 10px;
	--border-radius-1: 6px;
	--header-height: 80px;
	--footer-height: 160px;
	--padding-h-main: clamp(28px, 8vw, 44px);
	--padding-h-alt: clamp(28px, 8vw, 88px);
	--side-menu-width: min(380px, 100vw);
	--ease-time: 0.15s;
	@include ease-fn-pair(ease-fn, 0.17, 0.67, 0.83, 0.67);
	@include ease-fn-pair(ease-fn-slide, 0.645, 0.045, 0.355, 1);
	@include ease-fn-pair(ease-cubic, 0.32, 0, 0.67, 0);
	@include ease-fn-pair(ease-sine, 0.12, 0, 0.39, 0);
}

html,
body {
	background-color: var(--background-color);
	scrollbar-color: light;
}

html.light-content,
html.light-content body {
	scrollbar-color: dark;
}

body {
	font-family: 'Europa', sans-serif;
	color: var(--primary-color);
}

.hidden {
	display: none;
}

.invisible {
	position: absolute !important;
	width: 1px;
	height: 1px;
	overflow: hidden;
	clip: rect(1px, 1px, 1px, 1px);
}

.grecaptcha-badge {
	visibility: hidden;
}
